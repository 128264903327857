import logo from '../logo.svg';

export default function Home() {
return (
  <>
    <header className="App-home">
      <h1>
      반가워요👋<br />
      서연우라고 해요.
      </h1>
      <button
        className="App-link"
        onClick={() => (window.location.href="https://about.yeonw.me/")}
        target="_blank"
        type="button"
        rel="noopener noreferrer"
      >
        더 알아보기
      </button>
    </header>
  </>
  );
}
